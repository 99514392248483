import React from "react";
import Background from "./Mienar.gif";
import "./App.css";

function App() {
  return (
    <main className="image-container">
      <img src={Background} alt="background" />
      <h1 className="overlay-text">Welcome to 06xarm</h1>
      <button type="button" className="btn btn-light">
        Primary
      </button>
    </main>
  );
}

export default App;
